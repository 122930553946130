import { useStaticQuery, graphql } from "gatsby"

const useLogo = () => {
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "acm-logo.png" }) {
        childImageSharp {
          fixed(width: 132) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return logo.file.childImageSharp.fixed
}

export default useLogo
