import React from "react"
import FacebookIcon from '../../static/facebook.svg'
import TwitterIcon from '../../static/twitter.svg'
import YoutubeIcon from '../../static/youtube.svg'
import InstagramIcon from '../../static/instagram.svg'
import WizardsLogo from "../../images/wizards.png"
import { Link } from "gatsby"

const Footer = () => (
  <div className="bg-light p-6" id="footer">
    <div className="">
      <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center">
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48">
            <div className="text-xs uppercase text-gray-500 font-medium">Home</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/#header">Landing</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/#theme">Theme</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/#important-dates">Important Dates</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/#keynote">Keynote Speakers</Link>
          </div>
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48">
            <div className="text-xs uppercase text-gray-500 font-medium">Author's Guide</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/guides/#abstracts">Call for Abstracts</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/guides/#poster-and-videos">Poster and Videos</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/guides/#important-dates">Important Dates</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/guides/#presentation">Presentation Formats</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/guides/#registration">Registration</Link>
          </div>
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48">
            <div className="text-xs uppercase text-gray-500 font-medium">Program</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/program">Program</Link>
            <div className="mt-12 md:mt-5 text-xs uppercase text-gray-500 font-medium">Abstracts</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to={"/accepted-abstracts"}>Accepted Abstracts</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to={"/abstract-booklet"}>Abstract Booklet</Link>
            <div className="mt-12 md:mt-5 text-xs uppercase text-gray-500 font-medium">ACM WAP</div>
            <a className="my-3 block whitespace-nowrap text-sm" href="https://women.acm.org/asia-pacific/" target="_blank" rel="noreferrer">ACMW-AP</a>
          </div>
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48">
            <div className="text-xs uppercase text-gray-500 font-medium">Committees</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/committee/">Organizing</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/committee/#acmwap">ACMW – Asia Pacific</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/committee/#celebration">Celebration 2021</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/committee/#program">Programs</Link>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/committee/#technical">Technical</Link>
          </div>
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48 ">
            <div className="text-xs uppercase text-gray-500 font-medium">Rising and Shining Stars</div>
            <Link className="my-3 block whitespace-nowrap text-sm" to="/rising-and-shining-stars">Rising and Shining Stars</Link>
            <div className="mt-12 md:mt-5 text-xs uppercase text-gray-500 font-medium">Host</div>
            <a className="my-3 block whitespace-nowrap text-sm" href="https://www.national-u.edu.ph/" target="_blank"
               rel="noreferrer">National University</a>
            <a className="my-3 block whitespace-nowrap text-sm" href="https://www.ivenu.co/" rel="noreferrer" target="_blank">Take a Tour</a>
          </div>
          <div className="md:px-5 md:py-5 py-3 w-1/2 md:w-1/3 lg:w-48 ">
            <div className="text-xs uppercase text-gray-500 font-medium">Connect with ACM-W</div>
            <div className="flex my-2">
              <a href="https://www.facebook.com/women.acm.org/" target="_blank" rel="noreferrer"><FacebookIcon className="fill-current text-primary h-6 mr-2" /></a>
              <a href="https://twitter.com/officialacmw" target="_blank" rel="noreferrer"><TwitterIcon className="fill-current text-primary h-6 mr-2" /></a>
              <a href="https://www.youtube.com/user/TheOfficialACM" target="_blank" rel="noreferrer"><YoutubeIcon className="fill-current text-primary h-6 mr-2" /></a>
            </div>
            <div className="text-xs uppercase text-gray-500 font-medium mt-5">Connect with NU</div>
            <div className="flex my-2">
              <a href="https://www.facebook.com/NationalUPhilippines" target="_blank" rel="noreferrer"><FacebookIcon className="fill-current text-primary h-6 mr-2" /></a>
              <a href="https://twitter.com/NationalUPhil" target="_blank" rel="noreferrer"><TwitterIcon className="fill-current text-primary h-6 mr-2" /></a>
              <a href="https://www.instagram.com/NationalUPH/" target="_blank" rel="noreferrer"><InstagramIcon className="fill-current text-primary h-6 mr-2" /></a>
            </div>
          </div>
      </div>
    </div>
    <div className="pt-2 ">
      <div className="flex items-center pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm flex-col md:flex-row max-w-6xl">
          <div className="text-center md:text-left flex-1">© 2021 National University Manila, Philippines. <br className="md:hidden"/> All Rights Reserved.</div>
          <div className="md:mt-0 mt-6 flex items-center text-center md:text-left flex-col md:flex-row">
            <img className="mb-2 md:mb-0 h-4" src={WizardsLogo} alt="NU Wizard's Circle"/>
            <div className="ml-2 text-xs text-gray-400">Developed by NU Wizards' Circle<span className="hidden md:inline-block">&nbsp;-&nbsp;</span><br className="md:hidden"/>Shane Francis Maglangit</div>
          </div>
      </div>
    </div>
  </div>
)

export default Footer
