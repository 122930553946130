import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import ChevronDown from "../../../static/chevron-down.svg"
import ClearIcon from "../../../static/clear.svg"
import ArrowDown from "../../../static/arrow-down.svg"

import useLogo from "../../../hooks/useLogo"
import Img from "gatsby-image"

const Navigation = ({ isSticky }) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (isSticky === false) window.addEventListener("scroll", handleScroll)
    else setHasScrolled(true)
  }, [])

  function handleScroll(event) {
    const scrollTop = window.pageYOffset
    setHasScrolled(scrollTop > 32)
  }

  function toggleMobileMenu() {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  function closeMobileMenu() {
    if (mobileMenuOpen) setMobileMenuOpen(false)
  }

  const menuItems = [
    { text: "Home", link: "/", isExternal: false },
    { text: "Abstracts", link: null, isExternal: false,
      subMenu: [
        { text: "Accepted Abstracts", link: "/accepted-abstracts", isExternal: false },
        { text: "Abstract Booklet", link: "/abstract-booklet", isExternal: false },
      ]
    },
    { text: "Authors Guide", link: "/guides", isExternal: false,
      subMenu: [
        { text: "Call for Abstracts", link: "/guides/#abstracts", isExternal: false },
        { text: "Poster and Videos", link: "/guides/#poster-and-videos", isExternal: false },
        { text: "Submission", link: "/guides/#submission", isExternal: false },
        { text: "Important Dates", link: "/guides/#important-dates", isExternal: false },
        { text: "Registration", link: "/guides/#registration", isExternal: false },
      ]
    },
    { text: "Program", link: "/program", isExternal: false },
    { text: "Rising and Shining Stars", link: "/rising-and-shining-stars", isExternal: false },
    { text: "Forum", link: "https://acmwap2021.boards.net", isExternal: true },
    { text: "Committees", link: "/committee", isExternal: false },
    { text: "ACMW-AP", link: "https://women.acm.org/asia-pacific", isExternal: true },
    { text: "Host", link: null, isExternal: false,
      subMenu: [
        { text: "National University", link: "https://www.national-u.edu.ph/", isExternal: true },
        { text: "Take a tour", link: "https://www.ivenu.co/", isExternal: true },
      ]
    },
    { text: "Contact", link: "/contact", isExternal: false },
  ]

  return (
    <div className={`${ hasScrolled || mobileMenuOpen ? "bg-white shadow-md" : "bg-transparent" } 
      ${ isSticky === false ? "fixed" : "sticky top-0 transition-all slow-fade duration-25"} w-full z-50`}>
      <div className={`${ hasScrolled || mobileMenuOpen ? "shadow-md p-3" : "bg-transparent p-6" } items-center flex`} >
        <div className={`${ hasScrolled || mobileMenuOpen ? "h-10" : "h-14" } transition-all ease-in`} >
          <Link to="/" className="focus:outline-none">
            <Img fixed={useLogo()} alt="ACMWAP" style={{ height: "100%" }} imgStyle={{ objectFit: "contain", objectPosition: "center center", }} />
          </Link>
        </div>
        <MobileMenuButton hasScrolled={hasScrolled} toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={mobileMenuOpen} />
        <div className="hidden 2hxl:flex lg:flex-1 lg:justify-end">
          <ul className={`${ hasScrolled ? "text-black" : "text-white" } flex items-center font-medium`} >
            {menuItems.map(item => {
              let inner, subMenu;

              if(item.isExternal) inner = <a className={"flex"} href={item.link} target={"_blank"} rel={"noreferrer"}>{item.text}{item.subMenu != null && <ArrowDown className={"fill-current"} style={{marginTop: "-2px"}}/>}</a>
              else inner = <Link className={"flex"} to={item.link}>{item.text}{item.subMenu != null && <ArrowDown className={"fill-current"} style={{marginTop: "-2px"}}/>}</Link>

              if(item.subMenu != null) {
                subMenu = (
                  <ul className="shadow-md transition-all ease-in z-10 left-0 mt-10 top-0 px-4 pt-2 bg-white text-black rounded whitespace-nowrap absolute">
                    {item.subMenu.map(subItem => {
                      let subInner;
                      if(subItem.isExternal) subInner = <a href={subItem.link} target={"_blank"} rel={"noreferrer"}>{subItem.text}</a>
                      else subInner = <Link to={subItem.link}>{subItem.text}</Link>
                      return <li className="hover:text-accent mb-2">{subInner}</li>
                    })}
                  </ul>
                )
              }

              return (
                <li className={`${ hasScrolled ? "hover:text-accent" : "hover:border-accent" } border-b-4 border-transparent px-2 py-2 relative hover-child  transition-all ease-in`} >
                  {inner}
                  {subMenu}
                </li>
              )
            })}
            <li>
              <Link className={`${hasScrolled ? "bg-primary text-white transition-all ease-in transform hover:bg-primary-dark" : "bg-accent hover:bg-primary"} px-4 py-2 font-light ml-2 rounded-full transition-all ease-in relative`}
                to={"/thank-you"}>
                View Recorded Event
                <span className="absolute top-0 right-0 flex h-3 w-3">
                  <span className={`${hasScrolled ? "bg-accent" : "bg-white"} animate-ping absolute inline-flex h-full w-full rounded-full opacity-75`}></span>
                  <span className={`${hasScrolled ? "bg-accent" : "bg-white"} relative inline-flex rounded-full h-3 w-3`}></span>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <MobileMenu mobileMenuOpen={mobileMenuOpen} closeMobileMenu={closeMobileMenu} items={menuItems}/>
    </div>
  )
}

const MobileMenuButton = ({hasScrolled, toggleMobileMenu, mobileMenuOpen}) => (
  <div className="2hxl:hidden flex flex-1 items-center justify-end z-0">
    <button onClick={() => toggleMobileMenu()}
      className={`${ hasScrolled || mobileMenuOpen ? "text-accent" : "rounded border-white text-white border" } transition-all ease-in flex items-center justify-center h-10 w-10 focus:outline-none`} >
      {mobileMenuOpen && (
        <ClearIcon className={`h-6 w-6 transition-all ease-in fill-current`} />
      )}
      {!mobileMenuOpen && (
        <svg className={`h-6 w-6 transition-all ease-in fill-current`} viewBox="0 0 20 20" xmlns="https://www.w3.org/2000/svg" >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      )}
    </button>
  </div>
)

const MobileMenu = ({ mobileMenuOpen, closeMobileMenu, items}) => (
  <div className={`${ mobileMenuOpen ? "flex" : "hidden" } bg-white slow-fade duration-25 absolute left-0 shadow right-0 2hxl:hidden transition-all`} >
    <ul className={`${ mobileMenuOpen ? "text-black" : "text-white" } w-full flex flex-col font-medium`} >
      {items.map(item => {
        if(item.subMenu == null) {
          let inner;
          if(item.isExternal) inner = <a className={"flex"} href={item.link} target={"_blank"} rel={"noreferrer"}>{item.text}</a>
          else inner = <Link className={"flex"} to={item.link} onClick={() => closeMobileMenu()} >{item.text}</Link>
          return <li className="w-full px-12 py-2 border-b">{inner}</li>
        } else {
          return (
            <MobileNestedMenu link={item.link} linkText={item.text} closeMobileMenu={closeMobileMenu}>
              {item.subMenu.map(subItem => {
                let subInner;
                if(subItem.isExternal) subInner = <a href={subItem.link} onClick={() => closeMobileMenu()} target={"_blank"} rel={"noreferrer"}>{subItem.text}</a>
                else subInner = <Link onClick={() => closeMobileMenu()} to={subItem.link}>{subItem.text}</Link>
                return <li className="px-20 py-2 border-b">{subInner}</li>
              })}
            </MobileNestedMenu>
          )
        }
      })}
      <li className="w-full px-12 py-2 border-b">
        <Link className={"flex"} to={"/thank-you"}>View Recorded Event</Link>
      </li>
    </ul>
  </div>
)

const MobileNestedMenu = ({ children, closeMobileMenu, link, linkText }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <li className="w-full border-b">
      <div onClick={() => setIsExpanded(!isExpanded)} className="flex py-2 px-12" >
        <Link onClick={() => closeMobileMenu()} to={link}>{linkText}</Link>
        <div className="flex justify-end flex-1"><ChevronDown className={`${ isExpanded ? "-rotate-180" : "rotate-0" } transform transition-all ease-in mr-1`} /></div>
      </div>
      <ul className={`${ !isExpanded && "hidden" } border-t w-full whitespace-nowrap`} >{children}</ul>
    </li>
  )
}

export default Navigation
